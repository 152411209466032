import { Component, OnInit } from '@angular/core';
import { confirm } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Setting } from 'src/app/core/model/setting';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { DumpsterDetailDialogComponent } from '../dumpster-detail-dialog/dumpster-detail-dialog.component';
import { QuickBooksProduct } from 'src/app/core/model/quick-books-product';
import { DumpsterFeeTypeDialogComponent } from '../dumpster-fee-type-dialog/dumpster-fee-type-dialog.component';
import { Dumpster } from 'src/app/core/model/dumpster';
import { DumpsterCategory } from 'src/app/core/model/dumpster-category';
import { CreditCard } from 'src/app/core/model/credit-card';
import { CreditCardDialogComponent } from '../credit-card-dialog/credit-card-dialog.component';
import { FuelRatePercentage } from 'src/app/core/model/fuel-rate-percentage';

@Component({
    selector: 'app-table-edit',
    templateUrl: './table-edit.component.html',
    styleUrls: ['./table-edit.component.scss']
})
export class TableEditComponent implements OnInit {
    filter: any = { table: 'Credit Cards' };
    tables = ['Credit Cards', 'Dumpsters', 'Dumpster Categories', 'Dumpster Fee Types', 'QuickBooks Products', 'Sales People'];
    entities: any;
    maxDisplayOrder: number;

    dumpsters: Dumpster[];
    dumpsterCategories: DumpsterCategory[];

    settings: Setting[];
    activeFuelRatePercentage: FuelRatePercentage;
    fuelPercentage: number;
    fuelRate: number;
    kentCountyRate: number;
    overageRate: number;
    extendedOverageRate: number;
    serviceRate: number;
    extensionRate: number;
    averageExpensePerJob: number;
    averageExpensePerTon: number;
    emailSubject: string;
    emailBody: string;
    paymentEmailSubject: string;
    paymentEmailBody: string;
    statementEmailSubject: string;
    statementEmailBody: string;

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    constructor(private uow: CdrUnitOfWork, private dialogService: DialogService, private appInfo: AppInformationService, private auth: AuthService) {}

    async ngOnInit() {
        await this.getData();

        await this.getDumpsters();
        await this.getDumpsterCategories();
        await this.getFuelPercentage();

        this.settings = await this.uow.settings.all();
        this.fuelRate = this.settings.find(setting => setting.name === 'FuelRate').decimalValue;
        this.kentCountyRate = this.settings.find(setting => setting.name === 'KentCountyRate').decimalValue;
        this.overageRate = this.settings.find(setting => setting.name === 'OverageRate').decimalValue;
        this.extendedOverageRate = this.settings.find(setting => setting.name === 'ExtendedOverageRate').decimalValue;
        this.serviceRate = this.settings.find(setting => setting.name === 'ServiceRate').decimalValue;
        this.extensionRate = this.settings.find(setting => setting.name === 'ExtensionRate').decimalValue;
        this.averageExpensePerJob = this.settings.find(setting => setting.name === 'AverageExpensePerJob').decimalValue;
        this.averageExpensePerTon = this.settings.find(setting => setting.name === 'AverageExpensePerTon').decimalValue;
        this.emailSubject = this.settings.find(setting => setting.name === 'EmailSubject').stringValue;
        this.emailBody = this.settings.find(setting => setting.name === 'EmailBody').stringValue;
        this.paymentEmailSubject = this.settings.find(setting => setting.name === 'PaymentEmailSubject').stringValue;
        this.paymentEmailBody = this.settings.find(setting => setting.name === 'PaymentEmailBody').stringValue;
        this.statementEmailSubject = this.settings.find(setting => setting.name === 'StatementEmailSubject').stringValue;
        this.statementEmailBody = this.settings.find(setting => setting.name === 'StatementEmailBody').stringValue;
    }

    async getData() {
        switch (this.filter.table) {
            case 'Credit Cards': {
                this.entities = await this.uow.creditCards.whereActive('displayOrder, name');
                break;
            }
            case 'Dumpsters': {
                await this.getDumpsters();
                this.entities = this.dumpsters;
                break;
            }
            case 'Dumpster Categories': {
                await this.getDumpsterCategories();
                this.entities = this.dumpsterCategories;
                break;
            }
            case 'Dumpster Fee Types': {
                this.entities = await this.uow.dumpsterFeeTypes.whereActive('displayOrder', 'dumpsterFeeTypeDumpsterLinks');
                break;
            }
            case 'QuickBooks Products': {
                this.entities = await this.uow.getQBProducts();
                break;
            }
            case 'Sales People': {
                this.entities = await this.uow.salesPersons.whereActive('displayOrder, name');
                break;
            }
        }

        if (this.filter.table === 'Dumpsters' || this.filter.table === 'QuickBooks Products') return;
        if (!this.entities || this.entities.length === 0) {
            this.maxDisplayOrder = 1;
            this.entities = [];
        } else {
            this.maxDisplayOrder = ((_.maxBy(this.entities, 'displayOrder') as any)?.displayOrder ?? 0) + 1;
        }
    }

    async getFuelPercentage() {
        this.activeFuelRatePercentage = await this.uow.getActiveFuelRatePercentage();
        this.fuelPercentage = this.activeFuelRatePercentage.rate;
    }

    async getDumpsters() {
        this.dumpsters = await this.uow.dumpsters.all();
    }

    async getDumpsterCategories() {
        this.dumpsterCategories = await this.uow.dumpsterCategories.whereActive('displayOrder');
    }

    async selectionChanged(e) {
        await this.getData();
    }

    onReorder = async e => {
        var visibleRows = e.component.getVisibleRows(),
            toIndex = this.entities.indexOf(visibleRows[e.toIndex].data),
            fromIndex = this.entities.indexOf(e.itemData);

        this.entities.splice(fromIndex, 1);
        this.entities.splice(toIndex, 0, e.itemData);

        if (this.filter.table === 'Dumpsters' || this.filter.table === 'QuickBooks Products') return;
        this.updateDisplayOrder(this.entities);
        this.sortPartOptions(this.entities);
        await this.uow.commit();
    };

    updateDisplayOrder(rows: any[]) {
        rows.forEach((row, index) => {
            if (row.displayOrder !== index + 1) row.displayOrder = index + 1;
        });
    }

    sortPartOptions(rows: any[]) {
        rows.sort((a, b) => (a.displayOrder || 9999) - (b.displayOrder || 9999));
    }

    async rowDoubleClick(e) {
        await this.openSelection(e.data);
    }

    async openSelection(selectedRow?: any) {
        switch (this.filter.table) {
            case 'Credit Cards': {
                await this.openCreditCard(selectedRow);
                break;
            }
            case 'Dumpsters': {
                let dialogRef = this.dialogService.open(DumpsterDetailDialogComponent);
                dialogRef.instance.dumpster = selectedRow;
                const result = await dialogRef.instance.show();
                if (result.success) {
                    this.appInfo.pleaseWaitShow();
                    await this.getData();
                    this.appInfo.pleaseWaitHide();
                }
                this.dialogService.close(dialogRef);
                break;
            }
            case 'QuickBooks Products': {
                if (!selectedRow) break;
                this.editSetCode(selectedRow);
                break;
            }
            // case 'QuickBooks Products': {
            //     if (!selectedRow) break;
            //     const dialogRef = this.dialogService.open(InputDialogComponent);
            //     dialogRef.instance.dialogHeader = 'Set Type';
            //     dialogRef.instance.dialogPrompt = `Set Type For ${selectedRow.quickBooksProductService}`;
            //     dialogRef.instance.dialogValue = selectedRow.azureType;
            //     dialogRef.instance.dialogValueArray = QuickBooksProduct.azureTypes;
            //     dialogRef.instance.dialogWidth = 400;
            //     dialogRef.instance.mode = 'select';
            //     const result = await dialogRef.instance.show();

            //     if (result.success && result.value) {
            //         this.appInfo.pleaseWaitShow();
            //         this.entities.forEach(entity => {
            //             if (entity.azureType === result.value) entity.azureType = null;
            //         });
            //         selectedRow.azureType = result.value;
            //         await this.uow.commit();
            //     }
            //     this.dialogService.close(dialogRef);
            //     await this.getData();
            //     this.appInfo.pleaseWaitHide();
            //     break;
            // }
            case 'Dumpster Fee Types': {
                const dialogRef = this.dialogService.open(DumpsterFeeTypeDialogComponent);
                dialogRef.instance.dumpsterFeeType = selectedRow;
                const result = await dialogRef.instance.show();
                if (result.success) {
                    this.appInfo.pleaseWaitShow();
                    await this.getData();
                    this.appInfo.pleaseWaitHide();
                }
                this.dialogService.close(dialogRef);
                break;
            }
            default: {
                this.open(selectedRow);
                break;
            }
        }
    }

    async openCreditCard(creditCard?: CreditCard) {
        const dialogRef = this.dialogService.open(CreditCardDialogComponent);
        dialogRef.instance.creditCard = creditCard;
        const result = await dialogRef.instance.show();

        if (result.success) {
            this.appInfo.pleaseWaitShow();
            await this.getData();
        }
        this.dialogService.close(dialogRef);
        this.appInfo.pleaseWaitHide();
    }

    async editSetCode(quickBooksProduct: any) {
        const dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = 'Edit SET Code';
        dialogRef.instance.dialogPrompt = 'Edit SET Code';
        dialogRef.instance.dialogValue = quickBooksProduct.sETBillingCode;
        dialogRef.instance.mode = 'text';
        dialogRef.instance.dialogWidth = 350;
        const result = await dialogRef.instance.show();

        if (result.success) {
            this.appInfo.pleaseWaitShow();
            quickBooksProduct.sETBillingCode = result.value;
            await this.uow.commit();
        }
        this.dialogService.close(dialogRef);
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async open(selectedRow?: any) {
        const dialogRef = this.dialogService.open(InputDialogComponent);
        const header = selectedRow ? 'Edit Item' : 'Enter New Item';
        dialogRef.instance.dialogHeader = header;
        dialogRef.instance.dialogPrompt = header;
        dialogRef.instance.dialogValue = selectedRow?.name;
        dialogRef.instance.mode = 'text';
        dialogRef.instance.dialogWidth = 350;
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            let entity: any = null;
            switch (this.filter.table) {
                case 'Dumpster Categories': {
                    entity = selectedRow ?? this.uow.dumpsterCategories.createEntity();
                    entity.name = result.value;
                    break;
                }
                case 'Sales People': {
                    entity = selectedRow ?? this.uow.salesPersons.createEntity();
                    entity.name = result.value;
                    break;
                }
            }

            if (!entity.displayOrder) entity.displayOrder = this.maxDisplayOrder;
            await this.uow.commit();
        }
        this.dialogService.close(dialogRef);
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async delete(selectedRow: any) {
        const result = await confirm('Are you sure you want to delete this entry?', `${this.appInfo.company}`);
        if (!result) return;

        selectedRow.deleted = new Date();
        await this.uow.commit();
        await this.getData();
    }

    async setSetting(settingName: string, value: any, type: 'decimal' | 'int' | 'string' = 'string') {
        const setting = this.settings.find(setting => setting.name === settingName);
        if (!setting) return;

        if (type === 'decimal') setting.decimalValue = value ?? 0;
        else if (type === 'int') setting.intValue = value ?? 0;
        else if (type === 'string') setting.stringValue = value;

        await this.uow.commit();
        this.appInfo.notifyToast(`${settingName.split(/(?=[A-Z])/).join(' ')} Set`);
    }

    async setFuelPercentage() {
        const date = new Date();
        this.activeFuelRatePercentage.endDate = date;
        this.uow.fuelRatePercentages.createEntity({
            rate: this.fuelPercentage,
            startDate: date,
            created: date,
            createdBy: this.auth.userValue.userName
        });
        await this.uow.commit();
        await this.getFuelPercentage();
    }

    async toggleVisibility(selectedRows: any[]) {
        if (!selectedRows) return;

        this.appInfo.pleaseWaitShow();
        const isVisible = selectedRows[0].isVisible;
        selectedRows.forEach(selectedRow => (selectedRow.isVisible = !isVisible));
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
