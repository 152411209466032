// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Dumpster } from './dumpster';
import { DumpsterInventory } from './dumpster-inventory';
import { DumpsterLocation } from './dumpster-location';
import { FuelRatePercentage } from './fuel-rate-percentage';
import { InvoiceLine } from './invoice-line';
import { JobType } from './job-type';
import { Rental } from './rental';
import { RevenueLine } from './revenue-line';
import { StorageDumpSite } from './storage-dump-site';
import { User } from './user';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class Job extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  _nonCDRDumpster: boolean = false;

  get fullAddress() {
        return [this.address1, this.address2, this.city ? this.city + ',' : null, this.state, this.zip, this.country].join(' ');
    }

    get mobileHeader() {
        let text = '';
        if (this.jobTypeId === JobType.Types.Destination) text = 'Destination';
        else if (!!this.rental && !!this.dumpster) text = `${this.jobType.name} - ${this.dumpster.size}`;
        return text;
    }

    get mobileAddress() {
        return [
            this.siteName ? this.siteName + ' -' : null,
            this.address1,
            this.address2,
            this.city ? this.city + ',' : null,
            this.state,
            this.zip
        ].join(' ');
    }

    get displayId() {
        return `J${this.id}`;
    }

    get displayName() {
        return [this.siteName ? this.siteName + ' -' : null, this.address1].join(' ');
    }

    get displayAddress() {
        return [
            this.siteName ? this.siteName + ' -' : null,
            this.address1,
            this.address2,
            this.city ? this.city + ',' : null,
            this.state,
            this.zip,
            this.country
        ].join(' ');
    }

    get scheduleJobTypeName() {
        if (this.jobTypeId !== JobType.Types.Note) {
            if (!!this.jobType && !!this.action)
                return `J${this.id}: ${this.dumpsterId ? this.dumpster.size + ' -' : ''} ${this.jobType.name} ${
                    this.jobTypeId === 3 || this.jobTypeId === 4 ? this.action.name : ''
                } ${this.completed && this.inventory ? this.inventory.dumpsterBarcode : ''}`;
        } else return `J${this.id}: ${this.jobType.name}`;
    }

    get actionName() {
        if (this.jobTypeId === 3 || this.jobTypeId === 4) {
            if (this.actionId === 1) return 'Del';
            else if (this.actionId === 2) return 'Pick';
        } else return '';
    }

    get jobRevenue() {
        let revenue = 0;
        if (this.revenueLines && this.revenueLines.length > 0) revenue = _.sum(this.revenueLines.map(x => {return x.amount}));
        return revenue;
    }

    // get isDumpsterFromCDR() {
    //     const placedDumpster = this.placedDumpsterLocations?.find(d => !d.removed);
    //     return !placedDumpster || placedDumpster.dumpsterInventory.dumpsterNumber <= 500;
    // }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  actionId: number;
  address1: string;
  address2: string;
  audited: Date;
  billAsDumpsterId: number;
  city: string;
  completed: Date;
  country: string;
  created: Date;
  createdBy: string;
  deleted: Date;
  displayOrder: number;
  distanceFromPreviousJob: number;
  driverId: number;
  driverNotes: string;
  dumpsterBarcode: number;
  dumpsterId: number;
  endDate: Date;
  fuelRatePercentageId: number;
  inventoryId: number;
  jobTypeId: number;
  latitude: number;
  longitude: number;
  notes: string;
  preferredEndTime: Date;
  preferredStartTime: Date;
  rentalId: number;
  siteName: string;
  startDate: Date;
  started: Date;
  state: string;
  storageDumpSiteId: number;
  trailerUsed: boolean;
  weight: number;
  zip: string;
  action: JobType;
  billAsDumpster: Dumpster;
  driver: User;
  dumpster: Dumpster;
  fuelRatePercentage: FuelRatePercentage;
  inventory: DumpsterInventory;
  invoiceLines: InvoiceLine[];
  jobType: JobType;
  placedDumpsterLocations: DumpsterLocation[];
  removedDumpsterLocations: DumpsterLocation[];
  rental: Rental;
  revenueLines: RevenueLine[];
  storageDumpSite: StorageDumpSite;
}

