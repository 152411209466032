import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { AppInformationService, AuthService, CdrUnitOfWork, SessionInformationService } from 'src/app/core/core.module';
import { HistoryService } from 'src/app/core/history.service';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-invoice-history',
    templateUrl: './invoice-history.component.html',
    styleUrls: ['./invoice-history.component.scss']
})
export class InvoiceHistoryComponent implements OnInit {
    uow = inject(CdrUnitOfWork);
    appInfo = inject(AppInformationService);
    auth = inject(AuthService);
    sessionInfo = inject(SessionInformationService);
    historyService = inject(HistoryService);

    filter: { statusIds: number[]; includeDeleted: boolean; createdFrom: Date; createdTo: Date };
    invoices: any[];
    invoiceStatuses: InvoiceStatus[];
    isInvoiceAdmin = false;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    async ngOnInit() {
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        this.filter = this.sessionInfo.getItem('invoice-history-filter') ?? {
            statusIds: [InvoiceStatus.Statuses.Emailed, InvoiceStatus.Statuses.Printed],
            includeDeleted: false
        };
        this.isInvoiceAdmin = this.auth.hasRole('Invoice:Admin');
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        this.invoices = await this.historyService.invoiceHistory(this.filter);
        this.sessionInfo.setItem('invoice-history-filter', this.filter);
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },

            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async readyForExport() {
        const selectedRows = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        const invoiceIds = selectedRows.map(i => i.id);
        const invoices = (await this.uow.invoices.byIds(invoiceIds)) as Invoice[];

        if (invoices.some(invoice => invoice.quickBooksId)) {
            const success = await confirm(
                'One or more of the selected invoices have already been exported. Do you still want to mark them as ready to export?',
                this.appInfo.company
            );
            if (!success) return;
        }

        this.appInfo.pleaseWaitShow();
        const today = new Date();
        invoices.forEach(invoice => {
            if (!invoice.readyToExport) invoice.readyToExport = today;
            invoice.quickBooksId = null;
        });
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
