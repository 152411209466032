import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class HistoryService {
    http = inject(HttpClient);

    async invoiceHistory(filter: any) {
        filter.createdFrom = UtilityService.startOfDay(filter.createdFrom);
        filter.createdTo = UtilityService.startOfDay(filter.createdTo);
        return await firstValueFrom(this.http.post<any[]>(`${environment.serviceName}/history/invoice`, filter));
    }
}
